<template>
    <v-dialog max-width="500px"  v-model="show">
    <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <v-card>
          <v-toolbar flat dark color="panel1">
            <v-toolbar-title>Add New Notification Broadcast</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="show=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
            <div class="pa-5">

                            <v-row no-gutters>
                    <v-col cols="12">

                <v-form ref="mainForm">
                    <v-select
                        v-model = "form.notification_setting_id"
                        :items="notificationSettingList"
                        item-text="ns_name"
                        item-value="notification_setting_id"
                        label="Setting"
                        standard
                        :rules="inputRules"
                        outlined
                    ></v-select>
                    <v-select
                        v-model = "form.notification_template_id"
                        :items="notificationTemplateList"
                        item-text="nt_name"
                        item-value="notification_template_id"
                        label="Template"
                        standard
                        :rules="inputRules"
                        outlined
                    ></v-select>
                    <v-select
                        v-model = "form.notification_action_type_code"
                        :items="notificationActionList"
                        item-text="description"
                        item-value="code"
                        label="Action Type"
                        standard
                        :rules="inputRules"
                        outlined
                    ></v-select>                    
                    <v-text-field v-model="form.nb_code" label="Code" :rules="inputRules" outlined></v-text-field>
                    <v-text-field v-model="form.nb_name" label="Name" :rules="inputRules" outlined></v-text-field>
                    <v-spacer></v-spacer>
                </v-form>
                    </v-col>
                            </v-row>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="cbtnSave" dark @click="save">
                    <v-icon left dark>mdi-check</v-icon>
                    Submit
                </v-btn>
                <v-btn class="ml-2" color="cbtnRed" dark @click="show=false">
                    <v-icon left dark>mdi-close</v-icon>
                    Close
                </v-btn>
            </v-card-actions>

          </v-card>
    </v-dialog>

</template>

<script>
import DlgMessage from '@/components/DlgMessage'
import ApNotificationService from "@/services/ApNotificationService"
import ApParameterService from "@/services/ApParameterService"
export default {
    name: "DlgNotificationBroadcastAdd",
    props: {
        visible:Boolean,
    },      
    components:{DlgMessage},
    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
                this.$emit('close')
           }
        }
      },
    },
    data(){
        return{
            dialog: false,
            form:{
                login_id: "",
                notification_broadcast_id:0,
                notification_setting_id:0,
                notification_template_id:0,
                notification_action_code:"",
                nb_code: "",
                nb_name: "",
                status: "ACTIVE"
            },
            notificationSettingList:[],
            notificationActionList:[],
            notificationTemplateList:[],
            error_message:'',
            error_code: '200',
            inputRules: [
             v => !!v || 'This field is required'
            ]
        }
    },
    async created() {
//        this.$refs.mainForm.reset()
      //this.viewMerchantEdc();
      this.getParameter();
    },
     
    methods:{
   dlgShow(){
    },
    dlgClose(){
        console.log(this.respCode);
        
        if(this.respCode == 200){
            this.form.c_code = "";
            this.form.c_name = "";
            this.form.sequence = 0;
            this.show = false;
        }
            
    },                
        async save(){
            console.log(this.form);
            if(this.$refs.mainForm.validate()) {
                try{
                
                    ApNotificationService.insert_notification_broadcast(this.form).then(() => {
                        //console.log(res);
                        this.$refs.dlgMsg.showDlgBox(true,'success','New Notification Broadcast','Success.');
                    }).catch((e)=>{
                        //console.log(e.response);
                        this.$refs.dlgMsg.showDlgBox(true,'error','New Notification Broadcast',e.response.data.error_message);
                    })
                    ;
                } catch(e) {
                    this.loadingFlag = false
                    this.$refs.dlgMsg.showDlgBox(true,'error','New Notification Broadcast','Unknown Error');
                    
                } 
            }

        },
        async getParameter(){
            try{
                let parameter = {login_id:"",param_type:"add-notification_broadcast"}
                ApParameterService.get_parameter(parameter).then((res) => {
                    this.notificationActionList = res.data.notificationActionList;
                    this.notificationSettingList = res.data.notificationSettingList;
                    this.notificationTemplateList = res.data.notificationTemplateList;
                    console.log(res.data);
                    
                }).catch((e)=>{
                    console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Get Parameter',e.response.data.error_message);
                })
                ;
            } catch(e) {
                console.log(e);
                //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
            }
        },

       
    }
}    

</script>